<template>
	<div
		:id="`video-${_uid}`"
		:class="[
			'c-block-list-video-custom',
			'w-5col >=768:w-8col max-w-full mx-auto',
		]"
	>
		<LongReadTarget
			v-if="title"
			:disabled="Boolean(accordionNestingLevel)"
			:container-id="`video-${_uid}`"
			:title="title"
		>
			<BaseH2 class="text-primary-button mb-sm" v-text="title" />
		</LongReadTarget>

		<div
			v-if="picker"
			:class="[
				'c-block-list-video-custom__content',
				'relative w-full h-0 rounded-md overflow-hidden',
			]"
			:style="{ 'padding-top': `${aspectRatio * 100}%` }"
		>
			<CookieInformationBlocker
				:categories="picker.cookieCategories"
				class="absolute top-0 left-0 w-full h-full"
			>
				<VideoPlayer
					ref="videoPlayer"
					:picker="picker"
					:embed-on-play="true"
					class="absolute top-0 left-0 w-full h-full"
				>
					<template #overlay="{ overlay, service }">
						<button
							:class="[
								'relative w-full h-full',
								'duration-300 delay-200 ease-smooth-out',
								'group',

								{
									'opacity-0 pointer-events-none':
										!overlay.isVisible,
								},
							]"
							aria-label="Afspil video"
							@click.prevent="
								() => {
									service.play(overlay.hide);
									loading = true;
								}
							"
						>
							<img
								:src="service.poster"
								:class="[
									'absolute top-0 left-0',
									'w-full h-full object-cover',
								]"
							/>

							<div
								:class="[
									'absolute top-0 left-0',
									'w-full h-full bg-black bg-opacity-10',
									'duration-300 ease-smooth-out',
									{ 'group-hover:opacity-60': !loading },
								]"
							></div>

							<div
								:class="[
									'absolute top-1/2 left-1/2',
									'transform -translate-x-1/2 -translate-y-1/2',
									'w-40 h-40 rounded-full bg-white shadow-md',
									'flex justify-center items-center',
									'duration-300 ease-smooth-out',

									{
										'opacity-50': loading,
										'group-hover:scale-110': !loading,
										'scale-85 opacity-0':
											!overlay.isVisible,
									},
								]"
							>
								<SvgPlay
									class="ml-2 w-12 text-primary-button"
								/>
							</div>
						</button>
					</template>
				</VideoPlayer>
			</CookieInformationBlocker>
		</div>

		<div class="text-text s-rich-text mt-xs" v-html="text"></div>
	</div>
</template>

<script>
import { VideoPlayer } from '~/citi-baseline/components/VideoPlayer';
import LongReadTarget from '~/citi-baseline/components/LongReadTarget';
import CookieInformationBlocker from '~/components/shared/CookieInformationBlockerCustom';
import SvgPlay from '~/assets/svgs/play-icon.svg?inline';

export default {
	name: 'BlockListVideoCustom',
	components: {
		VideoPlayer,
		LongReadTarget,
		CookieInformationBlocker,
		SvgPlay,
	},

	inject: {
		accordionNestingLevel: {
			default: null,
		},
	},

	inheritAttrs: false,

	props: {
		title: {
			type: String,
			required: false,
		},
		text: {
			type: String,
			required: false,
		},
		video: {
			type: Object,
			required: false,
		},
	},

	data() {
		return {
			loading: false,
		};
	},

	computed: {
		picker() {
			return this.video?.content?.video
				? {
						details: {
							...(this.video?.content?.video?.details ?? {}),
							embed: this.video?.content?.video?.embed?.html,
						},
						provider: {
							alias: this.video?.alias
								?.replace('Block', '')
								.toLowerCase(),
						},
						cookieCategories: [
							'marketing',
							'functional',
							'statistic',
						].filter((c) => this.video?.content?.[c]),
				  }
				: false;
		},
		aspectRatio() {
			const html = this.video?.content?.video?.embed?.html;

			if (html) {
				const width = html.match(/width="([0-9]*)"/)[1];
				const height = html.match(/height="([0-9]*)"/)[1];

				if (width && height) {
					return parseInt(height) / parseInt(width);
				}
			}

			return 9 / 16;
		},
	},

	methods: {
		toggleVideo() {
			if (this.$refs?.videoPlayer) {
				const { videoPlayer } = this.$refs;
				const { playback } = videoPlayer.states;

				videoPlayer[playback ? 'pause' : 'play']();
				videoPlayer[playback ? 'hideOverlay' : 'showOverlay']();
				this.playing = !playback;
			}
		},
	},
};
</script>
