var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'c-block-list-video-custom',
		'w-5col >=768:w-8col max-w-full mx-auto' ],attrs:{"id":("video-" + _vm._uid)}},[(_vm.title)?_c('LongReadTarget',{attrs:{"disabled":Boolean(_vm.accordionNestingLevel),"container-id":("video-" + _vm._uid),"title":_vm.title}},[_c('BaseH2',{staticClass:"text-primary-button mb-sm",domProps:{"textContent":_vm._s(_vm.title)}})],1):_vm._e(),_vm._v(" "),(_vm.picker)?_c('div',{class:[
			'c-block-list-video-custom__content',
			'relative w-full h-0 rounded-md overflow-hidden' ],style:({ 'padding-top': ((_vm.aspectRatio * 100) + "%") })},[_c('CookieInformationBlocker',{staticClass:"absolute top-0 left-0 w-full h-full",attrs:{"categories":_vm.picker.cookieCategories}},[_c('VideoPlayer',{ref:"videoPlayer",staticClass:"absolute top-0 left-0 w-full h-full",attrs:{"picker":_vm.picker,"embed-on-play":true},scopedSlots:_vm._u([{key:"overlay",fn:function(ref){
		var overlay = ref.overlay;
		var service = ref.service;
return [_c('button',{class:[
							'relative w-full h-full',
							'duration-300 delay-200 ease-smooth-out',
							'group',

							{
								'opacity-0 pointer-events-none':
									!overlay.isVisible,
							} ],attrs:{"aria-label":"Afspil video"},on:{"click":function($event){$event.preventDefault();return (function () {
								service.play(overlay.hide);
								_vm.loading = true;
							}).apply(null, arguments)}}},[_c('img',{class:[
								'absolute top-0 left-0',
								'w-full h-full object-cover' ],attrs:{"src":service.poster}}),_vm._v(" "),_c('div',{class:[
								'absolute top-0 left-0',
								'w-full h-full bg-black bg-opacity-10',
								'duration-300 ease-smooth-out',
								{ 'group-hover:opacity-60': !_vm.loading } ]}),_vm._v(" "),_c('div',{class:[
								'absolute top-1/2 left-1/2',
								'transform -translate-x-1/2 -translate-y-1/2',
								'w-40 h-40 rounded-full bg-white shadow-md',
								'flex justify-center items-center',
								'duration-300 ease-smooth-out',

								{
									'opacity-50': _vm.loading,
									'group-hover:scale-110': !_vm.loading,
									'scale-85 opacity-0':
										!overlay.isVisible,
								} ]},[_c('SvgPlay',{staticClass:"ml-2 w-12 text-primary-button"})],1)])]}}],null,false,536055788)})],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-text s-rich-text mt-xs",domProps:{"innerHTML":_vm._s(_vm.text)}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }